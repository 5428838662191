.my-filter .input-range__label {
  display: none;
}

.my-filter .input-range__track--active {
  background-color: #1b1c177e;
}

.my-filter .input-range__slider {
  background-color: #868686;
  width: 10px;
  height: 10px;
  margin-top: -7px;
  border: none;
}

.my-filter .accordion__button {
  padding: 8px;
}

.my-filter .accordion__panel {
  padding: 8px;
}

.fc_filter {
  text-transform: uppercase;
}

.fc_title_popper {
  text-transform: uppercase;
}

.fc_popper {
  padding: 8px 16px;
  border-right: 1px solid;
}

.fc_last_popper > div {
  border-right: none;
}

.fc_panel {
  min-width: 300px;
  background-color: white;
  border-radius: 4px;
  padding: 16px;
  margin-top: 28px;
  margin-left: -16px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.178);
  max-height: 400px;
  overflow-y: auto;
}

.fc_bold {
  font-weight: 600;
}

.mobile-filter {
  z-index: 120;
  top: 54px;
  left: 0;
  padding: 8px;
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.my-filter .accordion__panel {
  overflow-y: auto;
  max-height: 300px;
}
