.my_button {
  border-radius: 5px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
}
.my_button .line {
  transition: width 0.25s;
}
.my_button:hover .line {
  transition: width 0.25s;
  width: 64px;
}
