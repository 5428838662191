.home-produit-reference .arrow-el-wrapper .arrow-container {
  transition: width 0.25s;
}
.home-produit-reference .arrow-el-wrapper .image-reference-produit {
  transform: scale(1);
  transition: transform 0.25s;
}
.home-produit-reference .arrow-el-wrapper:hover .arrow-container {
  transition: width 0.25s;
}
@media (min-width: 1024px) {
  .home-produit-reference .arrow-el-wrapper:hover .arrow-container {
    width: 80px;
  }
}
.home-produit-reference .arrow-el-wrapper:hover .image-reference-produit {
  transform: scale(1.1);
  transition: transform 0.25s;
}
.service-item-home .service-item-image-home img {
  transform: scale(1);
  transition: transform 0.25s;
}
.service-item-home .line-container {
  transition: width 0.25s;
}
.service-item-home:hover .service-item-image-home img {
  transform: scale(1.1);
  transition: transform 0.25s;
}
.service-item-home:hover .line-container {
  transition: width 0.25s;
}
@media (min-width: 1024px) {
  .service-item-home:hover .line-container {
    width: 64px;
  }
}
